@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

/**custom css for react dates responsive*/
@media only screen and (max-width: 700px) {
    .DayPicker_transitionContainer {
        padding-bottom: 37px !important;
        box-sizing: content-box !important;
    }
    
}